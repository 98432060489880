<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Logs de acesso</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "createdAt" },
        { text: "Usuário", value: "userName", sortable: false },
        { text: "IP", value: "ip", sortable: false },
        { text: "Descrição", value: "description", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    async loadLogs() {
      try {
        this.loading = true;

        const response = await this.$axios.get("/access-logs");

        this.setResponseData(response.data);

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },

    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.userName = item.User.Person.fullName;

        item.createdAt = moment(item.createdAt).format("DD/MM/YYYY [ás] HH:mm");

        return item;
      });
    },
  },
  computed: {},
  created() {
    this.loadLogs();
  },
  beforeDestroy() {},
};
</script>

<style>
</style>
